const SidebarData = [
    {
        label: "Menü",
        isMainMenu: true,
    },
    {
        label: "Ana Sayfa",
        icon: "mdi mdi-home-variant-outline",
        url: "/dashboard",
        bgcolor: "bg-primary",
        isHasArrow: true,
    },
    {
        label: "Tahmin - Kupon Ekle",
        icon: "mdi mdi-plus",
        isHasArrow: true,
        url: "/add",
    },

    {
        label: "Vip İçin Ekle",
        icon: "mdi mdi-diamond-stone",
        isHasArrow: true,
        url: "/add-vip",
    },
    
    {
        label: "Düzenlemeler",
        isMainMenu: true,
    },


    {
        label: "Tahminler",
        icon: "mdi mdi-notebook-edit",
        isHasArrow: true,
        url: "/predictions",
    },

    {
        label: "Kuponlar",
        icon: "mdi mdi-note-text",
        isHasArrow: true,
        url: "/coupons",
    },

    {
        label: "Vip Maçlar",
        icon: "mdi mdi-diamond-stone",
        isHasArrow: true,
        url: "/vip-matches",
    },

    {
        label: "Kaplumbaa Ayo",
        isMainMenu: true,
    },

    {
        label: "Tahmin - Kupon Ekle",
        icon: "mdi mdi-turtle",
        isHasArrow: true,
        url: "/testudoadd",
    },

    {
        label: "Vip İçin Ekle",
        icon: "mdi mdi-turtle",
        isHasArrow: true,
        url: "/testudoadd-vip",
    },

    {
        label: "Kaplumbaa Düzeni",
        isMainMenu: true,
    },

    {
        label: "Tahminler",
        icon: "mdi mdi-turtle",
        isHasArrow: true,
        url: "/testudopredictions",
    },

    {
        label: "Kuponlar",
        icon: "mdi mdi-turtle",
        isHasArrow: true,
        url: "/testudocoupons",
    },

    {
        label: "Vip Maçlar",
        icon: "mdi mdi-turtle",
        isHasArrow: true,
        url: "/vip-matches-testudo",
    },

    {
        label: "KULLANICILAR",
        isMainMenu: true,
    },

    {
        label: "Kullanıcılar (Mentor)",
        icon: "mdi mdi-account",
        isHasArrow: true,
        url: "/users/mentor",
    },
    
    {
        label: "Kullanıcılar (Bullco)",
        icon: "mdi mdi-account",
        isHasArrow: true,
        url: "/users/bullco",
    },

    {
        label: "Kullanıcılar (Zixo)",
        icon: "mdi mdi-account",
        isHasArrow: true,
        url: "/users/zixo",
    },

    {
        label: "Kullanıcılar (Testudo)",
        icon: "mdi mdi-account",
        isHasArrow: true,
        url: "/users/testudo",
    },

]
export default SidebarData;
