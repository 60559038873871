import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

import RadialChart1 from "./userpanelChart1";
import axios from "axios";
import moment from "moment";

const UserPanel = () => {
  const [newUser, setNewUser] = useState();
  const [vipUsers, setVipUsers] = useState();
  const [total, setTotal] = useState();

  const [newUserBullco, setNewUserBullco] = useState();
  const [vipUsersBullco, setVipUsersBullco] = useState();
  const [totalBullco, setTotalBullco] = useState();

  const [newUserZixo, setNewUserZixo] = useState();
  const [vipUsersZixo, setVipUsersZixo] = useState();
  const [totalZixo, setTotalZixo] = useState();

  const [newUserTestudo, setNewUserTestudo] = useState();
  const [vipUsersTestudo, setVipUsersTestudo] = useState();
  const [totalTestudo, setTotalTestudo] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(
        `${process.env.REACT_APP_BOSS}/admin/user`
      );

      const result2 = await axios.get(
        `${process.env.REACT_APP_BULLCO}/admin/user`
      );

      const result3 = await axios.get(
        `${process.env.REACT_APP_ZIXO}/admin/user`
      );

      const result4 = await axios.get(
        `${process.env.REACT_APP_TESTUDO}/admin/user`
      );

      const temp = result.data.users.filter(
        (item) => item.joinDate === moment().format("DD/MM/YYYY")
      );

      const temp10 = result2.data.users.filter(
        (item) => item.joinDate === moment().format("DD/MM/YYYY")
      );

      const temp20 = result3.data.users.filter(
        (item) => item.joinDate === moment().format("DD/MM/YYYY")
      );

      const temp30 = result4.data.users.filter(
        (item) => item.joinDate === moment().format("DD/MM/YYYY")
      );

      const temp2 = temp.length;
      const temp3 = result.data.users.filter((item) => item.isVip === true);
      const temp4 = temp3.length;
      const temp5 = result.data.users.length;

      const temp11 = temp10.length;
      const temp12 = result2.data.users.filter((item) => item.isVip === true);
      const temp13 = temp12.length;
      const temp14 = result2.data.users.length;

      const temp21 = temp20.length;
      const temp22 = result3.data.users.filter((item) => item.isVip === true);
      const temp23 = temp22.length;
      const temp24 = result3.data.users.length;

      const temp31 = temp30.length;
      const temp32 = result4.data.users.filter((item) => item.isVip === true);
      const temp33 = temp32.length;
      const temp34 = result4.data.users.length;

      setNewUser(temp2);
      setVipUsers(temp4);
      setTotal(temp5);

      setNewUserBullco(temp11);
      setVipUsersBullco(temp13);
      setTotalBullco(temp14);

      setNewUserZixo(temp21);
      setVipUsersZixo(temp23);
      setTotalZixo(temp24);

      setNewUserTestudo(temp31);
      setVipUsersTestudo(temp33);
      setTotalTestudo(temp34);
    };

    fetchData();
  }, []);

  return (
    <React.Fragment>
      <Row>
        <Col xl={3} sm={6}>
          <Card>
            <CardBody>
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                  <div id="radialchart-1" className="apex-charts" dir="ltr">
                    <RadialChart1 />
                  </div>
                </div>

                <div className="flex-grow-1 overflow-hidden">
                  <h3 className="mb-1">Mentor</h3>
                  <p className="mb-1">Yeni Kullanıcı</p>
                  <h3 className="mb-3">{newUser}</h3>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col xl={3} sm={6}>
          <Card>
            <CardBody>
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                  <div id="radialchart-1" className="apex-charts" dir="ltr">
                    <RadialChart1 />
                  </div>
                </div>

                <div className="flex-grow-1 overflow-hidden">
                  <h3 className="mb-1">Mentor</h3>
                  <p className="mb-1">VIP Kullanıcı Sayısı</p>
                  <h3 className="mb-3">{vipUsers}</h3>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col xl={3} sm={6}>
          <Card>
            <CardBody>
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                  <div id="radialchart-1" className="apex-charts" dir="ltr">
                    <RadialChart1 />
                  </div>
                </div>

                <div className="flex-grow-1 overflow-hidden">
                  <h3 className="mb-1">Mentor</h3>
                  <p className="mb-1">Toplam Kullanıcı Sayısı</p>
                  <h3 className="mb-3">{total}</h3>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xl={3} sm={6}>
          <Card>
            <CardBody>
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                  <div id="radialchart-1" className="apex-charts" dir="ltr">
                    <RadialChart1 />
                  </div>
                </div>

                <div className="flex-grow-1 overflow-hidden">
                  <h3 className="mb-1">Bullco</h3>
                  <p className="mb-1">Yeni Kullanıcı</p>
                  <h3 className="mb-3">{newUserBullco}</h3>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col xl={3} sm={6}>
          <Card>
            <CardBody>
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                  <div id="radialchart-1" className="apex-charts" dir="ltr">
                    <RadialChart1 />
                  </div>
                </div>

                <div className="flex-grow-1 overflow-hidden">
                  <h3 className="mb-1">Bullco</h3>
                  <p className="mb-1">VIP Kullanıcı Sayısı</p>
                  <h3 className="mb-3">{vipUsersBullco}</h3>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col xl={3} sm={6}>
          <Card>
            <CardBody>
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                  <div id="radialchart-1" className="apex-charts" dir="ltr">
                    <RadialChart1 />
                  </div>
                </div>

                <div className="flex-grow-1 overflow-hidden">
                  <h3 className="mb-1">Bullco</h3>
                  <p className="mb-1">Toplam Kullanıcı Sayısı</p>
                  <h3 className="mb-3">{totalBullco}</h3>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xl={3} sm={6}>
          <Card>
            <CardBody>
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                  <div id="radialchart-1" className="apex-charts" dir="ltr">
                    <RadialChart1 />
                  </div>
                </div>

                <div className="flex-grow-1 overflow-hidden">
                  <h3 className="mb-1">Zixo</h3>
                  <p className="mb-1">Yeni Kullanıcı</p>
                  <h3 className="mb-3">{newUserZixo}</h3>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col xl={3} sm={6}>
          <Card>
            <CardBody>
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                  <div id="radialchart-1" className="apex-charts" dir="ltr">
                    <RadialChart1 />
                  </div>
                </div>

                <div className="flex-grow-1 overflow-hidden">
                  <h3 className="mb-1">Zixo</h3>
                  <p className="mb-1">VIP Kullanıcı Sayısı</p>
                  <h3 className="mb-3">{vipUsersZixo}</h3>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col xl={3} sm={6}>
          <Card>
            <CardBody>
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                  <div id="radialchart-1" className="apex-charts" dir="ltr">
                    <RadialChart1 />
                  </div>
                </div>

                <div className="flex-grow-1 overflow-hidden">
                  <h3 className="mb-1">Zixo</h3>
                  <p className="mb-1">Toplam Kullanıcı Sayısı</p>
                  <h3 className="mb-3">{totalZixo}</h3>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xl={3} sm={6}>
          <Card>
            <CardBody>
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                  <div id="radialchart-1" className="apex-charts" dir="ltr">
                    <RadialChart1 />
                  </div>
                </div>

                <div className="flex-grow-1 overflow-hidden">
                  <h3 className="mb-1">Testudo</h3>
                  <p className="mb-1">Yeni Kullanıcı</p>
                  <h3 className="mb-3">{newUserTestudo}</h3>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col xl={3} sm={6}>
          <Card>
            <CardBody>
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                  <div id="radialchart-1" className="apex-charts" dir="ltr">
                    <RadialChart1 />
                  </div>
                </div>

                <div className="flex-grow-1 overflow-hidden">
                  <h3 className="mb-1">Testudo</h3>
                  <p className="mb-1">VIP Kullanıcı Sayısı</p>
                  <h3 className="mb-3">{vipUsersTestudo}</h3>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col xl={3} sm={6}>
          <Card>
            <CardBody>
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                  <div id="radialchart-1" className="apex-charts" dir="ltr">
                    <RadialChart1 />
                  </div>
                </div>

                <div className="flex-grow-1 overflow-hidden">
                  <h3 className="mb-1">Testudo</h3>
                  <p className="mb-1">Toplam Kullanıcı Sayısı</p>
                  <h3 className="mb-3">{totalTestudo}</h3>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      
    </React.Fragment>
  );
};

export default UserPanel;

