import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import Dashboard from "../Pages/Dashboard";


// Import Authentication pages
import Login from "../Pages/Authentication/Login";

// Import Utility Pages
import Maintenance from "../Pages/Utility/Maintenance-Page";
import ComingSoon from "../Pages/Utility/ComingSoon-Page";
import Error404 from "../Pages/Utility/Error404-Page";
import Error500 from "../Pages/Utility/Error500-Page";

//New
import Add from "../Pages/Add";
import TestudoAdd from "../Pages/Add/TestudoAdd";
import AddVip from "../Pages/Add/ForVip";
import TestudoAddVip from "../Pages/Add/TestudoAddVip";
import Predictions from "../Pages/Predictions/Predictions";
import TestudoPredictions from "../Pages/Predictions/TestudoPredictions";
import Coupons from "../Pages/Predictions/Coupons";
import TestudoCoupons from '../Pages/Predictions/TestudoCoupon'
import Vips from "../Pages/Predictions/Vip";
import TestudoVip from "../Pages/Predictions/TestudoVip";
import SingleCoupon from "../Pages/Predictions/SingleCoupon";
import TestudoSingleCoupons from "../Pages/Predictions/TestudoSingleCoupon";
import Mentor from "../Pages/Users/Mentor";
import Bullco from "../Pages/Users/Bullco";
import Zixo from "../Pages/Users/Zixo";
import Testudo from "../Pages/Users/Testudo";

const authProtectedRoutes = [
  //dashboard
  { path: "/dashboard", component: <Dashboard /> },

  // Calender
  { path: "/add", component: <Add /> },
  { path: "/testudoadd", component: <TestudoAdd /> },

  { path: "/add-vip", component: <AddVip /> },
  { path: "/testudoadd-vip", component: <TestudoAddVip /> },

  { path: "/predictions", component: <Predictions /> },
  { path: "/testudopredictions", component: <TestudoPredictions /> },

  { path: "/coupons", component: <Coupons /> },
  { path: "/testudocoupons", component: <TestudoCoupons /> },

  { path: "/vip-matches", component: <Vips /> },
  { path: "/vip-matches-testudo", component: <TestudoVip /> },

  { path: "/edit-coupon/:id", component: <SingleCoupon /> },
  { path: "/testudoedit-coupon/:id", component: <TestudoSingleCoupons /> },
  
  {path:"/users/mentor" , component:<Mentor />},
  {path:"/users/bullco" , component:<Bullco />},
  {path:"/users/zixo" , component:<Zixo />},
  {path:"/users/testudo" , component:<Testudo />},
{
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
];

const publicRoutes = [

  { path: "/login", component: <Login /> },

  // Utility Pages
  { path: "/pages-404", component: <Error404 /> },
  { path: "/pages-500", component: <Error500 /> },
  { path: "/pages-maintenance", component: <Maintenance /> },
  { path: "/pages-comingsoon", component: <ComingSoon /> },
];

export { authProtectedRoutes, publicRoutes };
